<template lang="pug">
  .subscriptions-page
    .uo-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .header.uo-header
      h1.nio-h1.text-primary-darker Onboarded Subscriptions
      NioButton(
        icon-name="utility-plus" 
        normal-primary-prepend
        @click="newOnboarding"
      ) New Onboarding
    .no-subscriptions(
      v-if="subscriptions && subscriptions.length === 0"
    )
      NioIconFramer(
        icon-name="display-list"
      )
      h3.nio-h3.text-primary-darker You have no onboarding lists
      p.nio-p.text-primary-dark Upload your offline customer data and find their digital matches.
      NioButton(
        normal-secondary
        @click="seeHow"
      ) See how onboarding works
    .subscriptions(
      v-else
      :class="{'free-version': appTier === 'free'}"
    )
      NioSlatTable(
        v-if="columns && subscriptions"
        :items="subscriptions"
        :columns="columns"
        :search-sort-header="appTier !== 'free'"
        :count-header="appTier === 'free'"
        :footer-actions="subscriptions.length === 1"
        :sort-options="sortOptions"
        :initial-items-per-page="30"
        :searchable-props="['orderName', 'orderNumber']"
        action="expand"
        pagination
      )
        template(v-slot:footer-actions)
          .free-version-footer(v-if="appTier === 'free'")
            NioIconFramer(
              icon-name="display-list-warning"
            )
            h3.nio-h3.text-primary-darker You've reached your onboarding limit
            p.nio-p.text-primary-dark Free tiers are ony allowed to onboard 1 file. Upgrade your app subscription to onboard more files.
            NioButton(
              normal-secondary
              @click="upgrade"
            ) Upgrade your App Tier
          NioButton(
            v-else 
            normal-primary-append 
            icon-name="utility-plus"
          ) New Item
        template(v-slot:item-expanded="slotProps") 
          .deactivating(v-if="deactivating && deactivateSubscriptionId && deactivateSubscriptionId === slotProps.item.id")
            v-progress-circular.progress(
              size="80" 
              color="#1438F5"
              indeterminate 
            )
          SubscriptionFileDownloadList(:subscription="slotProps.item")
          .subscription-actions(v-if="slotProps.item.status !== 'archived'")
            NioButton(
              caution-text 
              @click="deactivateSubscription(slotProps.item.id)"
            ) Stop Subscription
            
    NioDialog(
      v-model="confirmDeactivateDialog" 
    )
      ConfirmDeactivateDialog(
        @cancel="confirmDeactivateDialog = false"
        @confirm="confirmDeactivateSubscription"
      )        
</template>

<script>

import { mockSubscriptions } from './slatTableItems'
import { sortOptions } from './sortOptions'
import SubscriptionFileDownloadList from "./SubscriptionFileDownloadList"
import numeral from 'numeral'
import ConfirmDeactivateDialog from './ConfirmDeactivateDialog'
import { NioOpenApiModule } from '@narrative.io/tackle-box'

export default {
  components: { SubscriptionFileDownloadList, ConfirmDeactivateDialog },
  data: () => ({
    subscriptions: null,
    deactivating: {},
    loading: true,
    columns: null,
    sortOptions: sortOptions,
    appTier: 'free',
    confirmDeactivateDialog: false,
    deactivateSubscriptionId: null
  }),	
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    openApiInit() {
      this.getSubscriptions()
    },
    newOnboarding() {
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'new'
      },"*")
    },
    seeHow() {
      window.open('https://kb.narrative.io/universal-onboarding', '_blank')
    },
    computeOrderName(item) {
      return `${this.truncateString(item.name, 40)}`
    },
    computeOrderNumber(item) {
      return `#${item.id}`
    },
    computeBudget(item) {
      return `${numeral(item.budget.monthly.value.toFixed(2)).format('$0,0')} ${item.budget.monthly.currency}`
    },
    computeCreated(item) {
      return moment(item.created_at).format('MMM D, YYYY')
    },
    computeStatus(item) {
      return item.status === 'archived' ? 'Stopped' : 'Active'
    },
    truncateString(str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    },
    formatDate(date) {
      return `${date.getFullYear()}`
    },
    getSubscriptions() {
      this.$nioOpenApi.get('/subscriptions').then(res => {
        this.subscriptions = res.data.records.filter(subscription => subscription.status !== 'draft').reverse()
        this.makeColumns()
      })
      .finally(() => {
        this.loading = false
        this.deactivating = false
      })
    },
    makeColumns() {
      this.columns = [
        {
          name: "slat",
          props: {
            image: "imageSrc",
            title: this.computeOrderName,
            subtitle: this.computeOrderNumber
          }
        },
        { 
          name: 'status',
          label: 'Status',
          computed: this.computeStatus,
          addItemAsClass: true
        },
        {
          name: "budget",
          label: "Budget",
          computed: this.computeBudget
        },
        // {
        //   name: "created",
        //   label: "Created"
        // }
      ]  
    },
    upgrade() {
      window.open('https://app.narrative.io/app/universal-onboarding', '_blank')
    },
    deactivateSubscription(subscriptionId) {
      this.deactivateSubscriptionId = subscriptionId
      this.confirmDeactivateDialog = true
    },
    confirmDeactivateSubscription() {
      this.confirmDeactivateDialog = false
      this.deactivating = true
      this.$nioOpenApi.put(`/subscriptions/${this.deactivateSubscriptionId}/archive`).then(res => {
        this.getSubscriptions()
      })
    }
  }
};
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"

.subscriptions-page
  background-color: $c-white
  .header
    display: flex
    justify-content: flex-start
    align-items: flex-end
    position: relative
    margin-bottom: 24px
    height: 36px
    h1
      line-height: 1.75rem
    .nio-button
      position: absolute
      right: 0px
  .no-subscriptions, .free-version-footer
    padding: 155px 24px 179px 24px
    background-color: $c-canvas
    border: 1px solid $c-primary-lighter
    border-radius: 12px
    display: flex
    flex-direction: column
    align-items: center
    .nio-icon-framer
      margin-bottom: 16px
    h3
      margin-bottom: 8px
    p    
      margin-bottom: 40px
  .subscriptions.free-version
    padding: 0px !important     
    ::v-deep .nio-slat-table-actions
      padding: 0px !important  
    ::v-deep .nio-slat-table-pagination
      display: none
    .free-version-footer
      border: none
      border-radius: 0px
      width: 100
    ::v-deep .nio-slat-content
      width: 100%
    ::v-deep .nio-slat-action
      display: none  
    ::v-deep td:not(.expanded-row)
      padding-top: 24px
      padding-bottom: 24px
    ::v-deep .expanded-row
      border-bottom: 1px solid $c-primary-lighter
      position: relative
  ::v-deep .column-status.value-Stopped .nio-table-value
    color: $c-error  

  .subscription-actions
    display: flex
    justify-content: flex-end
    padding: 16px 44px
    background-color: $c-canvas
    border-top: 1px solid $c-primary-lighter
  
  .deactivating
    width: 100%
    height: 100%
    position: absolute
    .v-progress-circular
      left: 50%
      margin-left: -2.5rem
      top: 50%
      margin-top: -2.5rem
      z-index: 2
</style>