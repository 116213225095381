<template lang="pug">
  .file-downloads-list
    v-progress-circular.progress(
      v-if="loading" 
      size="40" 
      color="#1438F5"
      indeterminate 
    )
    .downloads(v-else)
      .nio-h3.text-primary-darker Downloadable files
      .nio-p.text-primary-dark Your first onboarding delivery will be ready in 1-2 hours after activation. Subsequent files will be available weekly.
      NioSlatTable( 
        v-if="fileDeliveries && fileDeliveries.length && headers"
        :items="fileDeliveries"
        :columns="headers"
        key="20"
        action="custom"
        listing-plain
        @itemClicked="downloadFile($event)"
      )
        template(v-slot:custom-action="slotProps") 
          NioButton(
            normal-secondary 
            @click="downloadFile(slotProps.item)"
          ) Download
      .no-files(v-else)
        .nio-p-large.text-primary-dark There are no available files to download. Please check back soon.
</template>

<script>

import moment from "moment"
import { NioOpenApiModule } from '@narrative.io/tackle-box'

export default {
  props: {
    subscription: { type: Object, required: true } 
  },
  data: () => ({
    fileDeliveries: [],
    loading: true,
    preparingDownload: false,
    headers: null
  }),
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    openApiInit() {
      this.getFileDeliveries()
    },
    computeHeaders() {
      this.headers = [
        {
          name: "slat",
          props: {
            image: '',
            title: this.fileName,
            subtitle: ''
          }
        },
        {
          name: "expiration",
          computed: this.daysToAccessExpiration
        }
      ]
    },
    truncateString(str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    },
    getFileDeliveries() {
      this.loadingFileDeliveries = true
      return this.$nioOpenApi.get(`/subscriptions/${this.subscription.id}/files`)
        .then(resp => {
          this.fileDeliveries = resp.data.records
          this.computeHeaders()
          this.loading = false
        })
    },
    daysToAccessExpiration(fileDelivery) {
      const expirationDate = moment.utc(fileDelivery.timestamp).add(31, 'days')
      return `Expires in ${expirationDate.diff(moment.utc(), 'days')} days`
    },
    fileName(fileDelivery) {
      const deliveryDate = moment.utc(fileDelivery.timestamp).format("YYYY-MM-DD")
      /*
       * `transactionBatchId` is a UUID. The first 8 characters of the UUID encode the the lowest 32 bits of the time
       * input to the UUID generation algorithm. It'll be safe for a long time to assume that no two batch ids for the
       * same order will be generated at the same microsecond, and we'd expect to only see collisions after ~2^16
       * deliveries for the same order **in a single day**. So this is fine.
       */
      const identifier = fileDelivery.transaction_batch_id.slice(0, 8)
      return this.truncateString(`${this.subscription.name}-${deliveryDate}-${identifier}.csv`, 50)
    },
    downloadFile(fileDelivery) {
      this.preparingDownload = true
      const req = {
        files: [{
          line_item_id: fileDelivery.line_item_id,
          transaction_batch_id: fileDelivery.transaction_batch_id,
          file_name: this.fileName(fileDelivery)
        }]
      }
      this.$nioOpenApi.post("/subscriptions/files/download", req)
        .then(resp => {
          this.preparingDownload = false
          const url = resp.data.records[0]
          this.downloadUrl(url)
        })
    },
    downloadUrl(url) {
      const a = document.createElement('a')
      a.href = url
      // NB: we can't overwrite the file name here because the request is cross origin and the Content-Disposition
      // header is part of what AWS signs to verify the presigned S3 download URL so we can't tamper with it.
      a.download = ''
      a.style.display = 'none';
      document.body.append(a)
      a.click()

      // According to the interwebz: Chrome requires this timeout
      this.delay(100)
        .then(() => a.remove())
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
  }
};
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"

.file-downloads-list
  position: relative
  min-height: 92px
  .v-progress-circular
    position: absolute
    left: 50%
    top: 24px
    margin-left: -20px
    z-index: 2
  padding: 24px
  background-color: $c-canvas
  .nio-h3
    margin-bottom: 2px
  .nio-p
    margin-bottom: 24px
  .no-files
    display: flex
    justify-content: center
    padding: 24px 0px
</style>